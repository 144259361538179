import React from 'react';
import { RichText } from 'prismic-reactjs';

import IndustryHeader from '@/containers/Industry/IndustryHeader';
import IndustryCategories from '@/containers/Industry/IndustryCategories';
import IndustryPartners from '@/containers/Industry/IndustryPartners';
import IndustryInspire from '@/containers/Industry/IndustryInspire';
import IndustryContactBox from '@/containers/Industry/IndustryContactBox';
import IndustryStats from '@/containers/Industry/IndustryStats';
import IndustryEnvironnement from '@/containers/Industry/IndustryEnvironnement';
import IndustryFabrication from '@/containers/Industry/IndustryFabrication';

import {
  IndustryPageQuery,
  PrismicCategoriesCategoriesGroupType,
  PrismicCompanyStatsStatsGroupType,
  PrismicPartnersPartnersGroupType,
} from '../../../graphql-types';

interface Props {
  data: IndustryPageQuery;
  type: string;
}

const IndustryContainer = ({ data, type }: Props): JSX.Element => {
  const {
    hero_title,
    hero_description,
    hero_cta,
    hero_cta_link,
    hero_background,
    categories_title,
    categories_description,
    categories_link,
    env_title,
    env_content,
    env_button,
    env_button_link,
    env_background,
    fabrication_title,
    fabrication_content,
    fabrication_button,
    fabrication_button_link,
    fabrication_background,
    // partners_title,
    // partners_mobile_title,
    // inspire_title,
    // inspirations,
    // inspires,
  } = data?.prismicIndustryPage?.data || {};

  // const { categories } = data?.prismicCategories?.data || {};

  // const { partners } = data?.prismicPartners?.data || {};

  const { primary } = data?.prismicIndustryPageBodyContactbox || {};

  const companyStats = data?.prismicIndustryPageBody1Companystats || {};

  // const {
  //   contact_box_background,
  //   contact_box_section_title,
  //   contact_box_content,
  // } = data?.prismicContactBox?.data || {};

  // const { stats } = data?.prismicCompanyStats?.data || {};

  const description =
    hero_description?.text?.length > 250
      ? {
          first: hero_description?.text?.slice(0, 150),
          last: hero_description?.text?.slice(
            150,
            hero_description?.text?.length,
          ),
        }
      : { first: hero_description?.text, last: '' };

  const HeaderProps = {
    title: <RichText render={hero_title?.raw} />,
    description,
    cta: {
      label: hero_cta,
      path: hero_cta_link,
    },
    background: {
      url: hero_background?.url,
      alt: hero_background?.alt,
    },
  };

  // const CategoriesProps = {
  //   title: <RichText render={categories_title?.raw} />,
  //   description: <RichText render={categories_description?.raw} />,
  //   button_link: categories_link,
  //   categories: categories?.map(
  //     (el: PrismicCategoriesCategoriesGroupType | null) => {
  //       const { image, title } = el || {};

  //       return {
  //         image: {
  //           url: image?.url,
  //           alt: image?.alt,
  //         },
  //         name: <RichText render={title?.raw} />,
  //       };
  //     },
  //   ),
  // };

  const EnvironnementProps = {
    title: <RichText render={env_title?.raw} />,
    description: <RichText render={env_content?.raw} />,
    cta: {
      label: env_button,
      path: env_button_link,
    },
    background: {
      url: env_background?.url,
      alt: env_background?.alt,
    },
  };

  const FabricationProps = {
    title: <RichText render={fabrication_title?.raw} />,
    description: <RichText render={fabrication_content?.raw} />,
    cta: {
      label: fabrication_button,
      path: fabrication_button_link,
    },
    background: {
      url: fabrication_background?.url,
      alt: fabrication_background?.alt,
    },
  };

  // const PartnersProps = {
  //   title: <RichText render={partners_title?.raw} />,
  //   mobileTitle: <RichText render={partners_mobile_title?.raw} />,
  //   partners: partners?.map((el: PrismicPartnersPartnersGroupType | null) => ({
  //     name: el?.name,
  //     link: el?.link?.url,
  //   })),
  // };

  // const InspireProps = {
  //   title: <RichText render={inspire_title?.raw} />,
  //   items: inspirations?.map((inspiration: any) => {
  //     const { image, title, link } = inspiration || {};

  //     return {
  //       background: {
  //         url: image?.url,
  //         alt: image?.alt,
  //       },
  //       title: <RichText render={title?.raw} />,
  //       link: link?.url,
  //     };
  //   }),
  // };

  // const InspireProps = {
  //   title: <RichText render={inspire_title?.raw} />,
  //   items: inspires?.map((inspire: any) => {
  //     //const { image, title, link } = inspire || {};
  //     const inspireData = inspire?.inspire?.document;

  //     return {
  //       link: `/magasin/actualites/${inspireData?.uid}`,
  //       background: {
  //         url: inspireData?.data?.hero_background?.url,
  //         alt: inspireData?.data?.hero_background?.alt,
  //       },
  //       title: <RichText render={inspireData?.data?.title?.raw} />,
  //     };
  //   }),
  // };

  // const BlogCategoriesProps = {
  //   blogCategories: categories?.map((el) => {
  //     const title = el?.category?.document?.data?.title?.raw;
  //     const content = el?.category?.document?.data?.content?.raw;
  //     const allArticles = el?.category?.document?.data?.articles;
  //     return {
  //       title: <RichText render={title} />,
  //       description: <RichText render={content} />,
  //       items: allArticles?.map((article) => ({
  //         title: (
  //           <RichText render={article?.article?.document?.data?.title?.raw} />
  //         ),
  //         background: {
  //           url: article?.article?.document?.data?.hero_background?.url,
  //           alt: article?.article?.document?.data?.hero_background?.alt,
  //         },
  //         link: article?.article?.document?.uid,
  //       })),
  //     };
  //   }),
  // };

  // const ContactBoxProps = {
  //   background: {
  //     url: contact_box_background?.url,
  //     alt: contact_box_background?.alt,
  //   },
  //   title: <RichText render={contact_box_section_title?.raw} />,
  //   content: <RichText render={contact_box_content?.raw} />,
  // };

  const ContactBoxProps = {
    title: <RichText render={primary?.title?.raw} />,
    content: <RichText render={primary?.content?.raw} />,
    button: {
      text: <RichText render={primary?.button_name?.raw} />,
      link: primary?.link,
    },
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
  };

  const StatsProps = {
    stats: companyStats?.items?.map((item) => ({
      number: <RichText render={item?.number?.raw} />,
      label: item?.label?.text,
    })),
  };

  return (
    <>
      <IndustryHeader {...HeaderProps} />
      {/* <IndustryCategories {...CategoriesProps} /> */}
      <IndustryEnvironnement {...EnvironnementProps} />
      <IndustryFabrication {...FabricationProps} />
      {/* <IndustryPartners {...PartnersProps} /> */}
      {/* <IndustryInspire {...InspireProps} /> */}
      {primary && <IndustryContactBox {...ContactBoxProps} />}
      {companyStats?.items?.length > 0 && <IndustryStats {...StatsProps} />}
    </>
  );
};

export default IndustryContainer;
