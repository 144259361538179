import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo/Seo';
import IndustryContainer from '@/containers/Industry/IndustryContainer';

import { IndustryPageQuery } from '../../../graphql-types';

interface Props {
  data: IndustryPageQuery;
}

export const pageQuery = graphql`
  query IndustryPage {
    prismicIndustryPage {
      data {
        meta_title
        meta_description
        ...IndustryHeader
        ...IndustryEnvironnement
        ...IndustryFabrication
      }
    }
    prismicIndustryPageBody1Companystats {
      primary {
        type
      }
      items {
        number {
          raw
        }
        label {
          text
        }
      }
    }
    prismicIndustryPageBodyContactbox {
      primary {
        title {
          raw
        }
        content {
          raw
        }
        button_name {
          raw
        }
        link
        image {
          url
          alt
        }
      }
    }
    prismicCategories {
      data {
        ...Categories
      }
    }
    prismicPartners {
      data {
        ...Partners
      }
    }
    prismicCompanyStats {
      data {
        ...CompanyStats
      }
    }
  }
`;

const Industry = ({ data }: Props): JSX.Element => {
  const { meta_title, meta_description } =
    data?.prismicIndustryPage?.data || {};

  return (
    <Layout invertNav type="usine">
      <Seo
        title={meta_title || 'Industry'}
        description={meta_description || 'This is our industry page'}
      />
      <IndustryContainer data={data} type="usine" />
    </Layout>
  );
};

export default Industry;
