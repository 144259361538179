import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import Button from '@/components/atoms/Button';
import Link from '@/components/atoms/Link';
import Background from '@/components/atoms/Background';
import Img from '@/components/atoms/Img';
import Typography from '@/components/atoms/Typography';
import Grid from '@/components/atoms/Grid';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';
import LinkDataType from '@/types/data/link';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  cta?: LinkDataType;
  background?: ImageDataType;
}

const IndustryEnvironnementRoot = styled('div')`
  /* margin-bottom: ${({ theme }) => theme.spacing(10)}; */
`;

const IndustryEnvironnementContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;

  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(15)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;
const LeftSideGrid = styled(Grid)`
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  order: 2;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  ${mq('lg')} {
    padding-right: 0;
    padding-left: ${({ theme }) => theme.spacing(10.5)};
    order: 2;
  }
`;
const RightSideGrid = styled(Grid)`
  order: 1;

  ${mq('lg')} {
    order: 1;
  }
`;
const Title = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(5)};

  ${mq('lg')} {
    margin-top: 0;
  }
`;
const Description = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(3)};
  font-weight: 500;
`;
const Cta = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
const HeroBg = styled(Img)`
  background-size: cover;
  /* transform: skew(2deg, 0deg); */
  width: 100%;
  height: 260px;
  ${mq('md')} {
    height: 400px;
  }

  ${mq('lg')} {
    border-radius: 10px;
  }
`;

const IndustryEnvironnement = ({
  title,
  description,
  cta,
  background,
}: Props): JSX.Element => {
  const { label, path } = cta || {};

  return (
    <IndustryEnvironnementRoot>
      <IndustryEnvironnementContainer maxWidth="lg">
        <Grid container>
          <LeftSideGrid xxs={12} lg={7}>
            <Title variant="h2">{title}</Title>
            <Description>{description}</Description>
            <Cta as={Link} {...{ to: path }} size="sm">
              {label}
            </Cta>
          </LeftSideGrid>
          <RightSideGrid xxs={12} lg={5}>
            <HeroBg src={background?.url || ''} alt={background?.alt || ''} />
          </RightSideGrid>
        </Grid>
      </IndustryEnvironnementContainer>
    </IndustryEnvironnementRoot>
  );
};

export const query = graphql`
  fragment IndustryEnvironnement on PrismicIndustryPageDataType {
    env_title {
      raw
    }
    env_content {
      raw
    }
    env_button
    env_button_link
    env_background {
      url
      alt
    }
  }
`;

export default IndustryEnvironnement;
