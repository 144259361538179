import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Typography from '@/components/atoms/Typography';

interface StatProps {
  number?: React.ReactNode;
  label?: string | null;
}

export interface CompanyStatsProps {
  stats?: StatProps[];
}

const Number = styled(Typography)`
  line-height: 1;
`;
const Label = styled(Typography)``;

const Stat = ({ number, label }: StatProps): JSX.Element => {
  return (
    <div>
      <Number variant="h2">{number}</Number>
      <Label variant="textMd">{label}</Label>
    </div>
  );
};

const CompanyStats = ({ stats }: CompanyStatsProps): JSX.Element => {
  const Stats = stats?.map((el: StatProps, i: number) => (
    <Stat key={i.toString()} {...el} />
  ));

  return <>{Stats}</>;
};

export const query = graphql`
  fragment CompanyStats on PrismicCompanyStatsDataType {
    stats {
      number {
        raw
      }
      label
    }
  }
`;

export default CompanyStats;
